import React, { useContext, useEffect, useState } from "react"
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  getMultiFactorResolver,
  linkWithCredential,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  TotpMultiFactorGenerator,
  updateEmail,
  updatePassword,
} from "firebase/auth"
import { auth } from "~/services/firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function resendVerificationEmail() {
    return sendEmailVerification(currentUser)
  }

  function sendVerificationEmail(user) {
    return sendEmailVerification(user)
  }

  function linkAnonymousAccount(email, password) {
    try {
      const credential = EmailAuthProvider.credential(email, password)
      return linkWithCredential(auth.currentUser, credential)
    } catch (error) {
      console.log(error)
    }
  }

  function applyAuthActionCode(code) {
    return applyActionCode(auth, code)
  }

  function checkAuthActionCode(code) {
    return checkActionCode(auth, code)
  }

  function confirmPasswordResetWithActionCode(code, newPassword) {
    return confirmPasswordReset(auth, code, newPassword)
  }

  async function signin(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function signout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function changeEmail(email) {
    return updateEmail(currentUser, email)
  }

  function changePassword(password) {
    return updatePassword(currentUser, password)
  }

  function reauthenticate(password) {
    var credential = EmailAuthProvider.credential(currentUser.email, password)

    return reauthenticateWithCredential(currentUser, credential)
  }

  function deleteAccount() {
    return deleteUser(currentUser)
  }

  function verifyIfUserIsEnrolledInMultiFactor() {
    return multiFactor(auth.currentUser).enrolledFactors.length > 0
  }

  async function verifyPhoneNumber(phoneNumber, applicationVerifier) {
    const session = await multiFactor(auth.currentUser).getSession()
    const phoneInfoOptions = {
      phoneNumber,
      session,
    }
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    return await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      applicationVerifier
    )
  }

  async function enrollUserInMultiFactor(
    phoneNumber,
    verificationCodeId,
    verificationCode
  ) {
    const phoneAuthCredential = PhoneAuthProvider.credential(
      verificationCodeId,
      verificationCode
    )
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(phoneAuthCredential)
    return await multiFactor(auth.currentUser).enroll(
      multiFactorAssertion,
      "My personal phone number"
    )
  }

  async function verifyUserEnrolled(verificationMFA, verificationCode) {
    const { verificationId, resolver } = verificationMFA
    const credentials = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    )
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credentials)
    return await resolver.resolveSignIn(multiFactorAssertion)
  }

  async function resendVerificationCode(recaptchaVerifier, resolver) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    }

    // Send SMS verification code.
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then(function (verificationId) {
        return verificationId
        // verificationId will be needed for sign-in completion.
      })

    return null
  }

  async function verifyMFA(multifactorError, recaptchaVerifier, selectedIndex) {
    const resolver = getMultiFactorResolver(auth, multifactorError)

    if (
      resolver.hints[selectedIndex].factorId ===
      PhoneMultiFactorGenerator.FACTOR_ID
    ) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[selectedIndex],
        session: resolver.session,
      }

      // Send SMS verification code.
      const phoneAuthProvider = new PhoneAuthProvider(auth)
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
      )
      return { verificationId, resolver }
    } else if (
      resolver.hints[selectedIndex].factorId ===
      TotpMultiFactorGenerator.FACTOR_ID
    ) {
      console.log("Error: TotpMultiFactorGenerator used")
    } else {
      console.log("Error: Unsupported second factor.")
    }
    return false
  }

  async function unenrollUserInPhoneMultiFactor() {
    const multiFactorUser = multiFactor(auth.currentUser)

    var options = multiFactorUser.enrolledFactors
    let phoneOption = options.find(
      (option) => option.factorId === PhoneMultiFactorGenerator.FACTOR_ID
    )

    if (phoneOption === 0) {
      return false
    }

    return multiFactorUser.unenroll(phoneOption)
  }

  useEffect(() => {
    const unsubscribeIdToken = auth.onIdTokenChanged((user) => {
      setCurrentUser(user)
    })

    const unsubscribeAuthState = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return () => {
      unsubscribeIdToken()
      unsubscribeAuthState()
    }
  }, [])

  const value = {
    currentUser,
    signup,
    resendVerificationEmail,
    sendVerificationEmail,
    linkAnonymousAccount,
    applyAuthActionCode,
    checkAuthActionCode,
    confirmPasswordResetWithActionCode,
    signin,
    signout,
    resetPassword,
    changeEmail,
    changePassword,
    reauthenticate,
    deleteAccount,
    verifyIfUserIsEnrolledInMultiFactor,
    verifyPhoneNumber,
    enrollUserInMultiFactor,
    verifyMFA,
    verifyUserEnrolled,
    unenrollUserInPhoneMultiFactor,
    resendVerificationCode,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
