import React, { useState } from "react"
import { motion } from "framer-motion"
import { useAnalytics } from "use-analytics"
import { Button } from "~/components/ui"
import {
  formatTimestampAsNoteTitle,
  formatTimestampAsNoteTitleShort,
} from "~/utils/helperFunctions"
import ClientModal from "./ClientModal"
import { Note, NoteStatus } from "./types"

interface NoteListItemProps {
  note: Note
  showSetClient: boolean
  fetchNotes?: () => void
  onClick: () => void
}

interface ClickableItemProps {
  children: React.ReactNode
  onClick?: () => void
}

const ClickableItem: React.FC<ClickableItemProps> = ({ children, onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLElement).tagName === "BUTTON") {
      return
    }
    onClick?.()
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      onClick?.()
    }
  }

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      className="flex rounded-3xl shadow flex-col cursor-pointer mb-2 md:mb-4 w-full"
    >
      <div className="flex h-full flex-col justify-center px-6 py-5 bg-white rounded-3xl w-full">
        {children}
      </div>
    </div>
  )
}

function NoteListItemProcessing({
  text,
  note,
}: Readonly<{ text: string; note: Note }>) {
  const textArray = text.split("")
  const [iteration, setIteration] = useState(0)

  const handleComplete = () => {
    // Trigger re-render to reset animation
    setTimeout(() => {
      setIteration((prev) => prev + 1)
    }, 500) // Pause for 0.5 second before restarting animation
  }

  return (
    <div className="flex flex-col">
      <motion.p
        key={iteration} // Reset animation on each iteration
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{
          delay: text.length * 0.1 + 2, // Keep delay consistent with the text animation below
          duration: 1, // Duration of the fade-out effect
        }}
        onAnimationComplete={handleComplete} // Restart the animation after fade-out
        className="text-xl md:text-2xl font-bold font-platypi"
      >
        {textArray.map((el, i) => (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: text.length * 0.01,
              delay: i * 0.1, // delay each character
              ease: "linear",
              repeatDelay: text.length * 0.1 + 2,
            }}
            key={i}
            style={{ display: "inline-block" }} // ensure elements don't break lines
          >
            {el === " " ? "\u00A0" : el}
          </motion.span>
        ))}
      </motion.p>

      <p className="text-medium-grey-500 text-sm font-normal">
        Recorded{" "}
        {formatTimestampAsNoteTitle(note.createdAt).toLocaleLowerCase()}
      </p>
    </div>
  )
}

function NoteListItemWithClient({ note }: Readonly<{ note: Note }>) {
  return (
    <div>
      <h5 className="font-platypi text-xl md:text-2xl font-bold text-black flex sm:mb-1 md:mb-2 capitalize">
        {
          note.title // Title set to client name
        }
      </h5>
      <p className="text-medium-grey-500 text-sm font-normal font-['SF Pro'] capitalize">
        {formatTimestampAsNoteTitleShort(note.createdAt)}
      </p>
    </div>
  )
}

function NoteListItemNoClient({ note }: Readonly<{ note: Note }>) {
  return (
    <h5 className="font-platypi flex items-center text-xl md:text-2xl font-bold text-black capitalize">
      {note.title === "My first note"
        ? "My first note"
        : formatTimestampAsNoteTitle(note.createdAt)}
    </h5>
  )
}

function NoteListItem({
  note,
  showSetClient,
  onClick,
}: Readonly<NoteListItemProps>) {
  const [openModal, setOpenModal] = useState(false)
  const { track } = useAnalytics()

  const renderNoteContent = () => {
    if (
      note.status === NoteStatus.Processing ||
      note.status === NoteStatus.Uploading
    ) {
      return (
        <div>
          <div className="sm:hidden">
            <NoteListItemProcessing
              text="Typing up..."
              note={note}
            />
          </div>
          <div className="hidden sm:block">
            <NoteListItemProcessing
              text="Typing up your note..."
              note={note}
            />
          </div>
        </div>
      )
    }
    if (note.clientId) {
      return <NoteListItemWithClient note={note} />
    }
    return <NoteListItemNoClient note={note} />
  }

  const handleClick = () => {
    if (
      note.status === NoteStatus.Uploading ||
      note.status === NoteStatus.Processing
    ) {
      return
    }
    onClick()
  }

  const handleShowSetClient = () => {
    setOpenModal(true)
    void track("note_list_item_set_client")
  }

  return (
    <>
      <ClientModal
        noteId={note.id}
        isOpen={openModal}
        onOpenChange={setOpenModal}
      />

      <ClickableItem onClick={handleClick}>
        <div className="flex justify-between">
          {renderNoteContent()}

          {showSetClient && (
            <div className="flex items-end">
              <Button
                variant="secondary"
                className="py-3 px-5 rounded-lg-plus text-[18px] font-medium font-['Archivo'] leading-snug"
                onClick={(event) => {
                  event.stopPropagation()
                  handleShowSetClient()
                }}
              >
                Set client
              </Button>
            </div>
          )}
        </div>
      </ClickableItem>
    </>
  )
}

export default NoteListItem
