import { SubscriptionDataType, SubscriptionState, TrialType } from '~/types/subscriptionTypes'
import { UserProfileType } from '~/types/userTypes'

const COUNTRIES_TO_SHOW_SUBSCRIPTION_INFO = ['GB', 'PL']

// Determines if the subscription info should be shown
export const shouldShowSubscriptionInfo = (
  userProfile: UserProfileType | null,
  subscriptionData: SubscriptionDataType | undefined,
): boolean => {
  if (!userProfile) {
    console.info('User profile is missing')
    return false
  }

  // If the user doesn't have a subscription data object then don't show the subscription info
  if (subscriptionData === undefined) {
    return false
  }

  // Show subscription info for eligible countries
  return COUNTRIES_TO_SHOW_SUBSCRIPTION_INFO.includes(userProfile.regCountryCode)
}

// Determines if the user has free notes remaining
const notesLeftToConsume = (subscriptionData: SubscriptionDataType) => {
  const { created, allowed } = subscriptionData?.notes || {}

  // If the user has no notes data, allow them to record note
  // TODO: Check if this is the correct behaviour, trigger error event maybe?
  if (created === undefined || allowed === undefined) {
    return true
  }

  // If the user has free notes remaining, allow them to record notes
  return created < allowed
}

// Determines if the user is allowed to record notes
export const isUserAllowedToRecordNewNote = (
  userProfile: UserProfileType | null,
  subscriptionData: SubscriptionDataType | undefined,
): boolean => {
  // If the user profile is missing, log an error and return false
  if (!userProfile) {
    console.info('User profile is missing')
    return false
  }

  // If the user doesn't have subscription data, allow them to record notes
  if (!subscriptionData) {
    return true
  }

  const subscriptionState = subscriptionData?.state

  // If the user doesn't have subscription state set yet, or are registered outside of GB
  // allow them to record notes
  if (!subscriptionState || userProfile?.regCountryCode !== 'GB') {
    return true
  }

  // If the user has subscribed, allow them to record notes
  if (subscriptionState === SubscriptionState.SUBSCRIBED) {
    return true
  }

  // If the user is on a trial, check the trial type
  if (subscriptionState === SubscriptionState.TRIAL) {
    const trialType = subscriptionData?.stateDetails?.trialType

    // If the trial type is free, allow the user to record notes
    if (trialType === TrialType.FREE) {
      const freeUntil = subscriptionData?.stateDetails?.freeUntil
      if (!freeUntil) {
        return false
      }
      return freeUntil && freeUntil.toDate() > new Date()
    }

    if (trialType === TrialType.CONSUME_NOTES) {
      return notesLeftToConsume(subscriptionData)
    }
  }

  if (subscriptionState === SubscriptionState.UNSUBSCRIBED) {
    return notesLeftToConsume(subscriptionData)
  }

  return false
}
