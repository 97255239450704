import React, { useContext, useEffect } from "react"
import * as Sentry from "@sentry/react"
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom"
import "@fontsource/archivo/500.css"
import "@fontsource/platypi/500.css"
import ErrorBoundary from "~/components/ErrorBoundary"
import PrivateRoute from "~/components/guards/PrivateRoute"
import { AuthProvider } from "~/context/AuthContext"
import { UserContext, UserProvider } from "~/context/UserContext"
import { useBeforeunload } from "~/hooks/useBeforeunload"
import useCrisp from "~/hooks/useCrisp"
import AuthPageLayout from "~/layouts/AuthPageLayout"
import Main from "~/layouts/Main"
import Account from "~/pages/Account/Account"
import SetupMFA from "~/pages/Account/SetupMFA"
import ActionHandler from "~/pages/Auth/ActionHandler"
import Login from "~/pages/Auth/Login"
import ResetPassword from "~/pages/Auth/ResetPassword"
import SignUp from "~/pages/Auth/SignUp"
import VerifyEmail from "~/pages/Auth/VerifyEmail"
import NoteDetails from "~/pages/Notes/NoteDetails"
import Notes from "~/pages/Notes/Notes"
import NoteTemplatePreview from "~/pages/Notes/NoteTemplatePreview"
import NoteTemplateSelection from "~/pages/Notes/NoteTemplateSelection"
import NotFound from "~/pages/NotFound"
import Recorder from "~/pages/Recorder/Recorder"
import Subscription from "~/pages/Subscription"
import { isSentryEnabled } from "./config/instrument"
import { Providers } from "./providers"
import "./pages/Account/account.css"
import "./pages/Notes/note.css"
import { BlockUI } from "./components/ui"

const BrowserRoutes = isSentryEnabled()
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes

function App() {
  const { initCrisp, resetSession } = useCrisp()

  // Initialize crisp
  useEffect(() => {
    initCrisp()
  }, [initCrisp])

  // Reset crisp session on page refresh
  useBeforeunload(() => resetSession())

  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <UserProvider>
            <Providers>
              <BrowserRoutes>
                <Route
                  path="/"
                  element={<PrivateRoute />}
                >
                  <Route
                    index
                    element={<RedirectToNotesOrRecorder />}
                  />
                  <Route
                    path="/recorder"
                    element={<Recorder />}
                  />
                  <Route element={<Main />}>
                    <Route
                      path="/account"
                      element={<Account />}
                    />
                    <Route
                      path="/setup-mfa"
                      element={<SetupMFA />}
                    />
                    <Route
                      path="/notes"
                      element={<Notes />}
                    />
                    <Route
                      path="/notes/:noteId"
                      element={<NoteDetails />}
                    />
                    <Route
                      path="/notes/:noteId/template-selection"
                      element={<NoteTemplateSelection />}
                    />
                    <Route
                      path="notes/:noteId/template-preview"
                      element={<NoteTemplatePreview />}
                    />
                  </Route>
                  <Route
                    path="*"
                    element={<NotFound />}
                  />
                </Route>
                <Route element={<AuthPageLayout />}>
                  <Route
                    path="/auth/action"
                    element={<ActionHandler />}
                  />
                  <Route
                    path="/login"
                    element={<Login />}
                  />
                  <Route
                    path="/sign-up"
                    element={<SignUp />}
                  />
                  <Route
                    path="/*"
                    element={<Navigate to="/sign-up" />}
                  />
                  <Route
                    path="/verify-email"
                    element={<VerifyEmail />}
                  />
                  <Route
                    path="/reset-password"
                    element={<ResetPassword />}
                  />
                </Route>
                <Route
                  path="/subscription"
                  element={<Subscription />}
                />
                <Route
                  path="*"
                  element={<NotFound />}
                />
              </BrowserRoutes>
            </Providers>
          </UserProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  )
}

function RedirectToNotesOrRecorder() {
  const userContext = useContext(UserContext)
  const statistics = userContext ? userContext.statistics : null

  if (!statistics) {
    return <BlockUI />
  }

  const hasRecordedNotes = statistics.totalSavedNotes > 0

  return hasRecordedNotes ? (
    <Navigate to="/notes" />
  ) : (
    <Navigate to="/recorder" />
  )
}

export default App
