import React from "react"
import Pincode from "~/components/Pincode"
import { Button } from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import type { MultiFactorResolver, PhoneMultiFactorInfo } from "firebase/auth"
import { ArrowLeftIcon } from "lucide-react"

type Props = {
  verificationId: string
  resolver: MultiFactorResolver
  successfulLogin: () => void
  handleCancel: () => void
  handleResendCode: () => void
  handleError: (error: string) => void
}

export function CodeLogin({
  verificationId,
  resolver,
  successfulLogin,
  handleCancel,
  handleResendCode,
  handleError,
}: Props) {
  const { verifyUserEnrolled } = useAuth()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  async function handleSubmit(code: string) {
    if (isSubmitting) return
    setIsSubmitting(true)

    // Verify the code
    try {
      await verifyUserEnrolled({ verificationId, resolver }, code)
      successfulLogin()
    } catch (error) {
      handleError("Failed to verify authentication code")
      setIsSubmitting(false)
    }

    setIsSubmitting(false)
  }

  return (
    <div className="flex flex-col gap-4 mb-6 justify-center items-center">
      <h2 className="text-2xl font-bold">Enter verification code</h2>
      <p className="text-center">
        We sent your verification code to{" "}
        {(resolver.hints[0] as PhoneMultiFactorInfo)?.phoneNumber ??
          "your phone number"}
        .
        <Button
          className="font-bold text-md inline p-2"
          variant="link"
          onClick={handleResendCode}
        >
          Resend
        </Button>
      </p>

      <div className="flex flex-row gap-1">
        <Pincode handleSubmit={handleSubmit} />
      </div>

      <Button
        className="py-1.5 px-4 -mt-2 text-sm text-black hover:text-primary"
        variant="link"
        size={"sm"}
        onClick={handleCancel}
      >
        <ArrowLeftIcon size={16} />
        Back to login
      </Button>
    </div>
  )
}
