import React, { useState } from 'react'
import { FirebaseError } from '@firebase/util'
import AlertModal from '~/components/AlertModal'
import { toast } from '~/components/ui'
import { LoginModal, LoginModalState } from '~/components/LoginModal'
import { useAuth } from '~/context/AuthContext'
import { MultiFactorResolver } from 'firebase/auth'
import { useRecaptcha } from '~/hooks/useRecaptcha'
import { useUserProfile } from '~/hooks/useUserProfile'
import { LockKeyhole } from 'lucide-react'

interface SecurityCardProps {
  navigateToMFASetup: () => void
  mfaEnabledCallback?: (mfa: boolean) => void
}

function SecurityCard({ navigateToMFASetup, mfaEnabledCallback }: SecurityCardProps) {
  const recaptcha = useRecaptcha('account-recaptcha-container')

  const { verifyMFA, verifyUserEnrolled, verifyIfUserIsEnrolledInMultiFactor, unenrollUserInPhoneMultiFactor, signin } =
    useAuth()
  const [mfaEnabled, setMfaEnabled] = useState(verifyIfUserIsEnrolledInMultiFactor())

  // Login modal
  const [openLoginDialog, setOpenLoginDialog] = useState<boolean>(false)
  const [loginModalState, setLoginModalState] = useState<LoginModalState>(LoginModalState.EMAIL_PASSWORD)

  // MFA disable modal
  const [disableMFAAlertOpen, setDisableMFAAlertOpen] = useState<boolean>(false)
  const [disablingMFA, setDisablingMFA] = useState<boolean>(false)
  const [verificationId, setVerificationId] = useState<string>('')
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null)
  const [isSubmittingCode, setIsSubmittingCode] = useState(false)

  // User profile
  const [userProfile, ,] = useUserProfile()

  const disableMFA = async () => {
    setDisablingMFA(true)
    try {
      await unenrollUserInPhoneMultiFactor()
      console.log('MFA disabled')
      setMfaEnabled(false)
      if (mfaEnabledCallback) {
        mfaEnabledCallback(false)
      }
      toast.success('Multi-factor authentication disabled')
    } catch (error) {
      if ((error as FirebaseError)?.code === 'auth/requires-recent-login') {
        setOpenLoginDialog(true)
      } else {
        toast.error('Failed to disable multi-factor authentication. Please try again.')
      }
    }
    setDisablingMFA(false)
  }

  const authenticateUser = async (data: { email: string; password: string }) => {
    try {
      const userCredential = await signin(data.email, data.password)
      if (userCredential.user?.uid === null) {
        throw new Error('User ID is null')
      }
      setOpenLoginDialog(false)
      await disableMFA()
    } catch (error: unknown) {
      handlePossibleMFA(error)
    }
  }

  const handlePossibleMFA = async (response: any) => {
    if (response && response.code === 'auth/multi-factor-auth-required' && recaptcha) {
      try {
        const { verificationId, resolver } = await verifyMFA(response, recaptcha, 0)
        setVerificationId(verificationId)
        setResolver(resolver)
        setLoginModalState(LoginModalState.AUTHENTICATION_CODE)
        await disableMFA()
      } catch (error) {
        toast.error('Failed to verify multi-factor authentication')
      }
    } else {
      toast.error(response)
    }
  }

  async function handleCode(code: string) {
    if (isSubmittingCode) {
      return
    }
    setIsSubmittingCode(true)

    try {
      // Verify the code
      const response = await verifyUserEnrolled({ verificationId, resolver }, code)

      if (response) {
        setLoginModalState(LoginModalState.EMAIL_PASSWORD)
        setOpenLoginDialog(false)
      } else {
        toast.error('Failed to verify authentication code')
      }
    } catch (error: unknown) {
      const firebaseError = error as FirebaseError
      if (firebaseError.code === 'auth/invalid-verification-code') {
        toast.error('Invalid authentication code')
      } else if (firebaseError.code === 'auth/auth/code-expired') {
        toast.error('Code expired.')
      } else {
        const errorString = error as string
        if (errorString) {
          toast.error(error as string)
        } else {
          toast.error('Failed to verify authentication code')
        }
      }
    }

    setIsSubmittingCode(false)
  }
  return (
    <>
      <div id="account-recaptcha-container" />

      <LoginModal
        open={openLoginDialog}
        state={loginModalState}
        title="You need to re-authenticate to continue"
        email={userProfile?.email ? userProfile?.email : ''}
        handleClose={() => setOpenLoginDialog(false)}
        handleLogin={authenticateUser}
        handleCode={handleCode}
      />

      <AlertModal
        open={disableMFAAlertOpen}
        onClose={() => setDisableMFAAlertOpen(false)}
        title="Disable multi-factor authentication?"
        description="Are you sure you want to disable multi-factor authentication?"
        confirm={disablingMFA ? 'Disabling...' : 'Disable'}
        dismiss="Cancel"
        onConfirm={async () => {
          await disableMFA()
          setDisableMFAAlertOpen(false)
        }}
      />

      <div className="bg-white shadow rounded-lg py-4 gap-y-3 gap-x-4">
        <h3 className="text-base leading-6 font-medium text-gray-900 px-4">Security</h3>

        <hr className="border-t border-gray-200 mt-4"></hr>

        <p className="text-sm font-medium pl-4 py-4">Multi-factor authentication</p>

        {mfaEnabled ? (
          <div className="flex flex-row items-center justify-center gap-2 px-2 py-1 sm:px-6">
            <p className="text-md">Multi-factor authentication is enabled</p>

            <label className="inline-flex items-center cursor-pointer" aria-label="disable multifactor authentication">
              <input type="checkbox" className="sr-only" checked={mfaEnabled} onChange={() => { setDisableMFAAlertOpen(true) }} />
              <div className="relative">
                <div className="block bg-gray-600 w-10 h-6 rounded-full"></div>
                <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${mfaEnabled ? 'translate-x-full border-green-500' : 'translate-x-0 border-gray-400'}`}></div>
              </div>
              <LockKeyhole className="w-6 h-6 text-blue-500 ml-2" />
            </label>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center px-2 pb-1 sm:px-6 gap-2">
            <p className="text-sm text-center">
              With multi-factor authentication, you can add an extra layer of security to your account. When you login
              to Joy Notes, you will be asked to enter a verification code sent to your phone number.
            </p>
            <button onClick={navigateToMFASetup} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none">
              Enable multi-factor authentication
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default SecurityCard
