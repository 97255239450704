import React, { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { addDoc, collection } from "firebase/firestore"
import {
  Button,
  Credenza as Dialog,
  CredenzaBody as DialogBody,
  CredenzaContent as DialogContent,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  toast,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { useClients } from "~/hooks/firestore/useClients"
import { useUpdateClientNote } from "~/hooks/useNotes"
import { db } from "~/services/firebase"
import { PLACEHOLDERS } from "~/utils/placeholders"

interface ClientModalProps {
  noteId: string
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  refetch?: () => void
}

const ClientModal: React.FC<ClientModalProps> = ({
  noteId,
  isOpen,
  onOpenChange,
  refetch,
}) => {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()

  const [showAddClientModal, setShowAddClientModal] = useState(false)

  const [creatingClient, setCreatingClient] = useState(false)
  const [clientName, setClientName] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    setClientName(name.trim())
  }

  const updateClientNote = useUpdateClientNote({
    onSuccess: () => {
      dismissDialogs()
      void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
      void queryClient.invalidateQueries({ queryKey: ["NOTES"] })
      void queryClient.invalidateQueries({ queryKey: ["CLIENTS"] })
      refetch?.()
    },
  })

  const createClient = async () => {
    const data = { name: clientName }

    const clientsCollectionRef = collection(
      db,
      `users/${currentUser.uid}/clients`
    )
    const clientRecord = await addDoc(clientsCollectionRef, data)

    await updateClientNote.mutateAsync({
      noteId,
      clientId: clientRecord.id,
      clientName: clientName,
    })
  }

  const handleCreateClient = async (): Promise<boolean> => {
    try {
      setCreatingClient(true)
      await createClient()
      return true
    } catch (error) {
      toast.error("Could not create client. Please try again.")
      return false
    } finally {
      setCreatingClient(false)
    }
  }

  const clientQuery = useClients()
  const clients = React.useMemo(
    () => (clientQuery.data ?? []).sort((a, b) => a.name.localeCompare(b.name)),
    [clientQuery.data]
  )

  const dismissDialogs = () => {
    setShowAddClientModal(false)
    onOpenChange(false)
  }

  const initialDialog = clients.length == 0 ? "ADD" : "SELECT"

  // Prevent modal from opening if noteId is not set
  if (!noteId) {
    return null
  }

  return (
    <>
      <Dialog
        open={isOpen && initialDialog === "SELECT"}
        onOpenChange={(open) => {
          // Don't close the modal if the add client modal is open
          if (showAddClientModal) return
          onOpenChange(open)
        }}
      >
        <DialogContent
          className="bg-primary-cream-300 border-0"
          aria-describedby=""
        >
          <DialogHeader className="py-1.5 border-b-[3px] border-b-[#eedecd]">
            <DialogTitle>
              <Button
                className="text-xl bg-transparent hover:bg-transparent"
                variant="ghost"
                onClick={() => setShowAddClientModal(true)}
              >
                Add new client
              </Button>
            </DialogTitle>
          </DialogHeader>

          <DialogBody
            data-vaul-no-drag=""
            className="overflow-y-auto max-h-96 md:max-h-[27rem] p-0"
          >
            <div className="divide-y divide-[#eedecd]">
              {clients.map((client) => (
                <Button
                  key={client.id}
                  size="lg"
                  variant="ghost"
                  isLoading={
                    updateClientNote.isPending &&
                    updateClientNote.variables?.clientId === client.id
                  }
                  className="w-full rounded-none flex justify-start h-auto p-4 cursor-pointer text-lg capitalize bg-primary-cream-300 hover:bg-primary-cream-500"
                  onClick={(event) => {
                    event.preventDefault()
                    if (client.id) {
                      updateClientNote.mutate({
                        noteId,
                        clientId: client.id,
                        clientName: client.name,
                      })
                    } else {
                      dismissDialogs()
                    }
                  }}
                >
                  {client.name}
                </Button>
              ))}
            </div>
          </DialogBody>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showAddClientModal || (isOpen && initialDialog === "ADD")}
        onOpenChange={(open) => {
          if (initialDialog === "ADD") {
            onOpenChange(open)
          } else {
            setShowAddClientModal(open)
          }
        }}
      >
        <DialogContent
          aria-describedby=""
          className="border-0 bg-primary-cream-300"
        >
          <DialogTitle className="sr-only">Add New Client</DialogTitle>

          <div
            data-vaul-no-drag=""
            className="mb-2 block content-center center mx-5"
          >
            <input
              id="create-client-input"
              className="border-none mt-[3.1rem] font-bold text-2xl haze bg-transparent w-full focus:outline-none text-center"
              onChange={handleInputChange}
              required
              placeholder={PLACEHOLDERS.NOTE.CLIENT.NAME}
            />
            <div className="container py-10 px-10 mx-0 min-w-full flex flex-col items-center">
              <Button
                disabled={creatingClient || clientName.trim().length === 0}
                onClick={async (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  const success = await handleCreateClient()
                  if (success) {
                    dismissDialogs()
                  }
                }}
                id="add-client-btn"
                variant="secondary"
              >
                Save new client
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ClientModal
