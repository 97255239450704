import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { BaseControl } from "~/components/base/base-control"
import { Form, Input, SubmitButton } from "~/components/ui"

type Props = {
  defaultEmail: string
  submitText?: string
  handleSubmit: (data: { email: string; password: string }) => void
}

export function LoginForm({
  defaultEmail,
  handleSubmit,
  submitText = "loginPage.general.sign.in",
}: Props) {
  const { t } = useTranslation()

  const form = useForm({
    defaultValues: {
      email: defaultEmail || "",
      password: "",
    },
  })

  return (
    <Form
      form={form}
      className="space-y-6"
      onSubmit={form.handleSubmit(async (values) => {
        await handleSubmit(values)
      })}
    >
      <BaseControl
        name="email"
        label={t("loginPage.general.email")}
        rules={{
          required: "Email is required",
        }}
        render={({ field }) => (
          <Input
            {...field}
            type="email"
            placeholder={t("loginPage.placeholder.email")}
          />
        )}
      />

      <BaseControl
        name="password"
        label={t("loginPage.general.password")}
        rules={{
          required: "Password is required",
        }}
        render={({ field }) => (
          <Input
            {...field}
            type="password"
            placeholder={"•••••••"}
          />
        )}
      />

      <SubmitButton
        type="submit"
        className="w-full"
        isSubmitting={form.formState.isSubmitting}
      >
        {t(submitText)}
      </SubmitButton>
    </Form>
  )
}
