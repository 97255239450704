import { isValidPhoneNumber } from 'libphonenumber-js'

enum FieldName {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  ZIP = 'zip',
}

enum ValidationPattern {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  NUMERIC = 'NUMERIC',
}

const ValidationPatterns = {
  [ValidationPattern.EMAIL]: /^\S+@\S+\.\S+$/,
  [ValidationPattern.PHONE]: /^[0-9]{10,15}$/,
  [ValidationPattern.NUMERIC]: /^[0-9]+$/,
}

const getValidationDetails = (fieldName: FieldName): { pattern: RegExp | null; errorMessage: string } => {
  switch (fieldName) {
    case FieldName.EMAIL:
      return { pattern: ValidationPatterns[ValidationPattern.EMAIL], errorMessage: 'Invalid email address' }
    case FieldName.ZIP:
      return { pattern: ValidationPatterns[ValidationPattern.NUMERIC], errorMessage: 'Only numbers are allowed' }
    default:
      return { pattern: null, errorMessage: 'Invalid value' }
  }
}

export const validateField = (
  field: { name: string; type: string; value: string },
  setValidationErrors: React.Dispatch<React.SetStateAction<string[]>>,
): boolean => {
  let isValid = true
  let error = ''

  const fieldName = field.name.toLowerCase() as FieldName

  if (fieldName === FieldName.PHONE) {
    if (!isValidPhoneNumber(field.value)) {
      isValid = false
    }
  } else if (field.value.trim() === '' && fieldName === FieldName.NAME) {
    isValid = false
    error = 'Name cannot be empty'
  } else if (field.value.trim() !== '') {
    const { pattern, errorMessage } = getValidationDetails(fieldName)
    if (pattern && !pattern.test(field.value)) {
      isValid = false
      error = errorMessage
    }
  }

  setValidationErrors((prevErrors) => {
    const newErrors = [...prevErrors]
    const errorIndex = newErrors.findIndex((err) => err.startsWith(field.name))
    if (isValid) {
      if (errorIndex !== -1) newErrors.splice(errorIndex, 1)
    } else {
      if (errorIndex === -1) {
        newErrors.push(`${field.name}: ${error}`)
      } else {
        newErrors[errorIndex] = `${field.name}: ${error}`
      }
    }
    return newErrors
  })

  return isValid
}
