import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const focusInput = [
  'focus:ring-2',
  'focus:ring-blue-200',
  'focus:border-blue-500',
]

export const focusRing = [
  'outline outline-offset-2 outline-0 focus-visible:outline-2',
  'outline-blue-500',
]

export const hasErrorInput = [
  'ring-2',
  'border-red-500',
  'ring-red-200',
]
