import { FirebaseError } from "@firebase/util"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  collection,
  deleteDoc,
  doc,
  QuerySnapshot,
  updateDoc,
} from "firebase/firestore"
import { Observable } from "rxjs"
import { useAnalytics } from "use-analytics"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions } from "~/lib/react-query"
import { Note } from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import { getNoteDateTitle } from "~/utils/helperFunctions"
import { useUserStatistics } from "../useUserStatistics"
import { useSubscription } from "./useSubscription"
import { fromRef } from "./utils"

export type Client = {
  name: string
  properties: {
    type: string
    value: string | { countryCode: number; number: number }
  }[]
  associatedNoteIds: string[]
  id: string
}

/* Queries */
export function useClients() {
  const { currentUser } = useAuth()

  return useSubscription<QuerySnapshot, FirebaseError, Client[]>({
    subscriptionKey: ["CLIENTS"],
    subscriptionFn: () => {
      return fromRef(
        collection(db, `users/${currentUser.uid}/clients`)
      ) as Observable<QuerySnapshot>
    },
    options: {
      select: (snapshot) => {
        return snapshot?.docs?.map((doc) => {
          const { name, properties, associatedNoteIds } = doc.data()

          return {
            id: doc.id,
            name,
            properties,
            associatedNoteIds,
          }
        })
      },
    },
  })
}

/* Mutations */
export function useDeleteClient(
  options?: MutationOptions<{
    clientId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationFn: async ({ clientId }) => {
      const noteRef = doc(db, `users/${currentUser?.uid}/clients/${clientId}`)
      await deleteDoc(noteRef)
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("client_deleted")
        void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
        void queryClient.invalidateQueries({ queryKey: ["NOTES"] })
        void queryClient.invalidateQueries({ queryKey: ["CLIENTS"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}

export function useRemoveClientFromNote(
  options?: MutationOptions<{
    note: Note
  }>
) {
  const queryClient = useQueryClient()
  const { track } = useAnalytics()

  const { currentUser } = useAuth()
  const [userStatistics] = useUserStatistics()

  return useMutation({
    ...options,
    mutationFn: async ({ note }) => {
      const noteRef = doc(db, `users/${currentUser?.uid}/notes/${note.id}`)
      const title = getNoteDateTitle(userStatistics, note)

      await updateDoc(noteRef, {
        title,
        clientId: null,
      })
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("note_removed_from_client")
        void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
        void queryClient.invalidateQueries({ queryKey: ["NOTES"] })
        void queryClient.invalidateQueries({ queryKey: ["CLIENTS"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}
