import React from "react"
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  PhoneInput,
} from "~/components/ui"
import { isValidPhoneNumber } from "libphonenumber-js"
import { useForm } from "react-hook-form"
import { SubmitButton } from "./ui/submit-button"

type Props = {
  title: string
  fieldName: string
  fieldValue: string
  handleSubmit: (data: {
    fieldName: string
    fieldValue: string
  }) => void | Promise<void>
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

const isPhoneValid = (phone: string) => {
  try {
    return isValidPhoneNumber(phone)
  } catch (error) {
    return false
  }
}

function EditPhoneNumberModal({
  title,
  fieldName,
  fieldValue,
  handleSubmit,
  children,
  open,
  onOpenChange,
}: React.PropsWithChildren<Props>) {
  const closeRef = React.useRef<HTMLButtonElement>(null)

  const form = useForm({
    values: {
      input: fieldValue,
    },
  })

  return (
    <Dialog
      open={open}
      onOpenChange={(_open) => {
        if (form.formState.isSubmitting) return
        onOpenChange?.(_open)
      }}
    >
      <DialogTrigger asChild>{children ?? null}</DialogTrigger>

      <DialogContent
        className="sm:max-w-[425px]"
        aria-describedby=""
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>

        <Form
          id="modal-form"
          form={form}
          onSubmit={form.handleSubmit(async (values) => {
            await handleSubmit({ fieldName, fieldValue: values.input })
            form.reset()
            setTimeout(() => closeRef.current?.click(), 10)
          })}
        >
          <FormField
            name="input"
            rules={{
              required: "This field is required",
              validate: (v) => (v && isPhoneValid(v)) || "Invalid phone number",
            }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PhoneInput
                    {...field}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    international
                    className="bg-transparent"
                    placeholder="Enter a phone number"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>

        <DialogFooter>
          <DialogClose asChild>
            <Button
              ref={closeRef}
              variant="ghost"
            >
              Cancel
            </Button>
          </DialogClose>

          <SubmitButton
            form="modal-form"
            className="min-w-24"
            isSubmitting={form.formState.isSubmitting}
          >
            Save
          </SubmitButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditPhoneNumberModal
