import { useCallback, useContext, useMemo, useTransition } from "react"
import { useAuth } from "~/context/AuthContext"
import { UserContext } from "~/context/UserContext"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "~/services/firebase"
import { UserStatisticsType } from "~/types/userTypes"

const isEqualPartial = (
  partial: Partial<UserStatisticsType>,
  full: UserStatisticsType | undefined
): boolean => {
  if (!full) return false

  return Object.keys(partial).every((key) => {
    const k = key as keyof UserStatisticsType
    return JSON.stringify(partial[k]) === JSON.stringify(full[k])
  })
}

export function useUserStatistics(): [
  UserStatisticsType | null,
  (updates: Partial<UserStatisticsType>) => void,
  boolean,
] {
  const user = useContext(UserContext)
  const { currentUser } = useAuth()

  const [isPending, startTransition] = useTransition()

  const updateUserStatistics = useCallback(
    (updates: Partial<UserStatisticsType>) => {
      if (!isEqualPartial(updates, user?.statistics)) {
        startTransition(() => {
          const userRef = doc(db, "users", currentUser?.uid)
          updateDoc(userRef, {
            statistics: { ...user?.statistics, ...updates },
          })
        })
      }
    },
    [currentUser?.uid, user?.statistics]
  )

  return useMemo(
    () => [user?.statistics || null, updateUserStatistics, isPending],
    [user?.statistics, updateUserStatistics, isPending]
  )
}
