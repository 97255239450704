import * as React from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui"
import { allCountries, Country } from "~/utils/countries"

interface CountrySelectorProps {
  value: Country | null
  handleSelectedCountry: (country: Country | null) => void

  props: Omit<
    React.ComponentPropsWithoutRef<typeof Select>,
    "value" | "onValueChange"
  >

  // Trigger props
  onBlur?: () => void
}

const CountrySelector = React.forwardRef<
  React.ElementRef<typeof SelectTrigger>,
  CountrySelectorProps
>(({ value, handleSelectedCountry: onChange, onBlur, ...props }, ref) => {
  return (
    <Select
      value={value ? `${value.code}` : undefined}
      onValueChange={(selectedCode) => {
        const country =
          allCountries.find((c) => String(c.code) === String(selectedCode)) ||
          null

        onChange(country)
      }}
      {...props}
    >
      <SelectTrigger
        ref={ref}
        onBlur={onBlur}
        className="w-full"
      >
        <SelectValue placeholder="Select country..." />
      </SelectTrigger>

      <SelectContent>
        {allCountries.map((item) => (
          <SelectItem
            key={item.code}
            value={`${item.code}`}
          >
            <Opt {...item} />
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
})

const Opt = (option: Country) => {
  return (
    <span className="flex items-center leading-none gap-3">
      {option.code !== "--" && (
        <img
          width="20"
          height="20"
          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
          loading="lazy"
          alt=""
        />
      )}

      <span className="flex-nowrap line-clamp-1 overflow-ellipsis">
        {option.name}
      </span>
    </span>
  )
}
CountrySelector.displayName = "CountrySelector"

export default CountrySelector
