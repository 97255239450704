import React from "react"
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui"
import { PROMPTS } from "~/utils/prompts"
import { LoginForm } from "./LoginForm"
import Pincode from "./Pincode"

export enum LoginModalState {
  EMAIL_PASSWORD = "EMAIL_PASSWORD",
  AUTHENTICATION_CODE = "AUTHENTICATION_CODE",
}

type LoginModalProps = {
  open: boolean
  title: string
  email: string
  state: LoginModalState
  handleClose: () => void
  handleLogin: (data: { email: string; password: string }) => void
  handleCode: (code: string) => void
}

export const LoginModal: React.FC<LoginModalProps> = ({
  open,
  title,
  email,
  state,
  handleClose,
  handleLogin,
  handleCode,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            handleClose?.()
          }
        }}
      >
        <DialogContent className="">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>
              {state === LoginModalState.EMAIL_PASSWORD ? (
                <p className="text-md">{PROMPTS.ENTER_EMAIL_PASSWORD}</p>
              ) : (
                <p className="text-md">{PROMPTS.ENTER_CODE}</p>
              )}
            </DialogDescription>
          </DialogHeader>

          <div id="login-form-modal-container">
            {state === LoginModalState.EMAIL_PASSWORD ? (
              <LoginForm
                defaultEmail={email}
                submitText="Authenticate"
                handleSubmit={handleLogin}
              />
            ) : (
              <Pincode handleSubmit={handleCode} />
            )}
          </div>

          <DialogFooter className="hidden">
            <DialogClose asChild>
              <Button variant="ghost">Cancel</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
