import * as React from "react"
import { Button, InputOTP, InputOTPGroup, InputOTPSlot } from "~/components/ui"

type Props = {
  handleSubmit: (code: string) => void
  pinLength?: number
  submitText?: string
}

export default function Pincode({
  handleSubmit,
  pinLength = 6,
  submitText = "Verify",
}: Props) {
  const [pin, setPin] = React.useState("")
  const submitButtonRef = React.useRef<HTMLButtonElement | null>(null)

  return (
    <div className="flex flex-col gap-6">
      <InputOTP
        value={pin}
        onChange={setPin}
        maxLength={pinLength}
        onComplete={(code: string) => {
          handleSubmit?.(code)
          setTimeout(() => submitButtonRef.current?.focus(), 0)
        }}
      >
        <InputOTPGroup>
          {Array.from({ length: pinLength }).map((_, index) => (
            <InputOTPSlot
              className="bg-white"
              key={index}
              index={index}
            />
          ))}
        </InputOTPGroup>
      </InputOTP>
      <Button
        type="button"
        ref={submitButtonRef}
        disabled={pin.length !== pinLength}
        variant="default"
        className="font-bold"
        onClick={() => handleSubmit(pin)}
      >
        {submitText}
      </Button>
    </div>
  )
}
