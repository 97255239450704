import { useEffect, useState } from 'react'

import { toast } from '~/components/ui'
import { isUserAllowedToRecordNewNote, shouldShowSubscriptionInfo } from '~/config/subscriptionPolicies'
import { useAuth } from '~/context/AuthContext'
import { collection, doc, getDocs, onSnapshot, query } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { db, functions } from '~/services/firebase'

import { SubscriptionDataType } from '~/types/subscriptionTypes'
import { useUserProfile } from './useUserProfile'

interface CheckoutSessionResponse {
  id: string
  sessionUrl: string
}

interface BillingPortalResponse {
  billingPortalUrl: string
}

interface Price {
  id: string
  active: boolean
}

// Custom hook to handle subscription status and actions
// such as upgrading subscription and opening the billing portal
const useSubscriptionHandler = () => {
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionDataType>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { currentUser } = useAuth()
  const [userProfile] = useUserProfile()
  const [fetchingBillingPortal, setFetchingBillingPortal] = useState<boolean>(false)
  const [fetchingCheckoutSession, setFetchingCheckoutSession] = useState<boolean>(false)

  const freeNotesRemaining = (): number | undefined => {
    if (subscriptionData?.notes?.allowed === undefined || subscriptionData?.notes?.created === undefined) {
      return undefined
    }
    const remainingNotes = Math.max(subscriptionData.notes.allowed - subscriptionData.notes.created, 0)
    return remainingNotes
  }

  useEffect(() => {
    setIsLoading(true)
    // Listen to subscription document changes
    const subscriptionDocRef = doc(db, `users/${currentUser?.uid}/profile/subscription`, '')
    const unsubscribe = onSnapshot(
      subscriptionDocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data() as SubscriptionDataType
          setSubscriptionData(data)
        }
        setIsLoading(false)
      },
      () => {
        setSubscriptionData(undefined)
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
      },
    )
    return () => unsubscribe()
  }, [currentUser])

  // Opens the Stripe billing portal in a this tab
  const openBillingPortal = async () => {
    if (fetchingBillingPortal) {
      return
    }
    setFetchingBillingPortal(true)
    // Get the billing portal function reference
    const createBillingPortalCloudFunction = httpsCallable<{}, BillingPortalResponse>(
      functions,
      'createBillingPortalLink',
    )

    try {
      const result = await createBillingPortalCloudFunction({ returnUrl: window.location.href })
      if (!result.data?.billingPortalUrl) {
        throw new Error('No portal link found in the response')
      }

      // Open the billing portal in this tab
      window.location.assign(result.data.billingPortalUrl)
    } catch (error) {
      console.error('Error getting billing portal link:', error)
      toast.error('Failed to get billing portal link. Please try again.')
    } finally {
      setFetchingBillingPortal(false)
    }
  }

  // Initiates a checkout session for a price ID (subscription plan)
  const initiateCheckoutSession = async (priceId: string): Promise<string> => {
    // Get the function reference
    const checkoutSessionCloudFunction = httpsCallable<{}, CheckoutSessionResponse>(functions, 'createCheckoutSession')

    try {
      const result = await checkoutSessionCloudFunction({ priceId })
      if (!result.data?.sessionUrl) {
        throw new Error('No session URL found in the response')
      }
      return result.data.sessionUrl
    } catch (error) {
      console.error('Error creating checkout session:', error)
      throw new Error('Failed to setup checkout session')
    }
  }

  // Fetch available products from Firestore
  const fetchProducts = async () => {
    const q = query(collection(db, 'products'))
    const querySnapshot = await getDocs(q)
    const docsArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }))
    return docsArray
  }

  // Initiates a checkout session in a new tab
  const upgradeSubscription = async () => {
    if (fetchingCheckoutSession) {
      return
    }
    setFetchingCheckoutSession(true)
    try {
      // Get the products
      const products = await fetchProducts()
      if (products.length === 0) {
        toast.error('No subscription options available. Please contact support if the issue persists.')
        return
      }

      // Get the first product and any active prices
      const productId = products[0]?.id
      if (!productId) {
        toast.error('Error retrieving subscription details. Please try again.')
        return
      }

      const pricesCollection = collection(db, 'products', productId, 'prices')
      const pricesSnapshot = await getDocs(pricesCollection)
      const prices: Price[] = pricesSnapshot.docs.map((doc) => ({ id: doc.id, active: doc.data().active }))
      const activePrices = prices.filter((price) => price.active)

      if (activePrices.length === 0) {
        toast.error('No active subscription options available. Please contact support for assistance.')
        return
      }

      // Get the id of the first active price
      const priceId = activePrices[0].id

      // Initiate the checkout session
      const sessionUrl = await initiateCheckoutSession(priceId)

      if (!sessionUrl) {
        toast.error('Failed to initiate checkout. Please try again or contact support if the issue persists.')
        return
      }

      // Open the checkout session in the current tab
      window.location.assign(sessionUrl)
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === 'Failed to setup checkout session') {
          toast.error('Checkout session creation failed. Please try again or contact support.')
          return
        }
      }
      toast.error('An unexpected error occurred. Please try again or contact support.')
      console.error('Error upgrading account:', error)
    } finally {
      setFetchingCheckoutSession(false)
    }
  }

  // Determine if the user is allowed to record notes
  const allowNotesRecording = () => {
    return isUserAllowedToRecordNewNote(userProfile, subscriptionData)
  }

  // Returns true if subscription info should be shown
  const showSubscriptionInfo = () => {
    return shouldShowSubscriptionInfo(userProfile, subscriptionData)
  }

  return {
    showSubscriptionInfo,
    allowNotesRecording,
    isLoading,
    subscriptionData,
    freeNotesRemaining,
    upgradeSubscription,
    fetchingCheckoutSession,
    openBillingPortal,
    fetchingBillingPortal,
  }
}

export default useSubscriptionHandler
