import React, { useState } from "react"
import { FirebaseError } from "@firebase/util"
import type { MultiFactorResolver } from "firebase/auth"
import { doc, increment, serverTimestamp, updateDoc } from "firebase/firestore"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import BoxContainer from "~/components/BoxContainer"
import { LoginForm } from "~/components/LoginForm"
import { Alert, AlertDescription, AlertTitle, Button } from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { useRecaptcha } from "~/hooks/useRecaptcha"
import { db } from "~/services/firebase"
import { CodeLogin } from "./CodeLogin"

export default function Login() {
  const recaptcha = useRecaptcha("login-recaptcha-container")

  const { signin, verifyMFA, resendVerificationCode, currentUser } = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>()
  const [verificationId, setVerificationId] = useState<string>()
  const [resolver, setResolver] = useState<MultiFactorResolver>()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const emailParam = params.get("email")
  const email = emailParam ?? ""
  const { t } = useTranslation()

  const handleLoginSuccessAsync = async () => {
    if (currentUser?.uid && currentUser?.emailVerified) {
      // Update user statistics
      const dataRef = doc(db, `users/${currentUser.uid}`)
      await updateDoc(dataRef, {
        "statistics.totalSignIns": increment(1),
        "statistics.lastSignIn": serverTimestamp(),
      })
    }

    // Navigate to home
    navigate("/")
  }

  const handleSubmitAsync = async (data: {
    email: string
    password: string
  }) => {
    try {
      // Clear any previous errors
      setError(undefined)

      const userCredential = await signin(data.email, data.password)

      const user = userCredential.user
      if (user?.uid === null) {
        throw new Error("User ID is null")
      }

      await handleLoginSuccessAsync()
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        // MFA required or other error
        handlePossibleMFAAsync(error)
      } else {
        console.error("Login error: ", error)
      }
    }
  }

  const handleResendCodeAsync = async () => {
    const result = await resendVerificationCode(recaptcha, resolver)
    if (result) {
      setVerificationId(result)
    } else {
      setError("Failed to resend verification code")
    }
  }

  const handleFirebaseError = (error: FirebaseError) => {
    const errorMessages: Record<string, string> = {
      "auth/invalid-login-credentials": "Invalid email address or password",
      "auth/too-many-requests": "Too many requests. Please try again later.",
    }

    const message =
      errorMessages[error.code] ||
      "Unknown authentication error. Please try again."
    setError(message)
  }

  const handlePossibleMFAAsync = async (error: FirebaseError) => {
    if (error.code === "auth/multi-factor-auth-required" && recaptcha) {
      try {
        const { verificationId, resolver } = await verifyMFA(
          error,
          recaptcha,
          0
        )
        setVerificationId(verificationId)
        setResolver(resolver)
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          handleFirebaseError(error)
        } else {
          console.error("Login error: ", error)
        }
      }
    } else {
      handleFirebaseError(error)
    }
  }

  return (
    <>
      <BoxContainer>
        {!verificationId && !resolver && (
          <>
            <div className="flex flex-col gap-4 items-center mb-8">
              <h1 className="text-5xl font-platypi">
                {t("loginPage.main.heading")}
              </h1>
              <p className="text-center my-2">
                {t("loginPage.main.description")}
              </p>
            </div>

            {error !== undefined ? (
              <Alert variant="destructive">
                <AlertTitle className="text-[#c41c1c]">
                  {t("loginPage.sign.in.failed")}
                </AlertTitle>
                <AlertDescription>
                  <p className="text-[#c41c1c]">{error.toString()}</p>
                  <p className="text-[#c41c1c]">
                    {t("loginPage.sign.in.again", {
                      support_email: "support@joy.day",
                    })}
                  </p>
                </AlertDescription>
              </Alert>
            ) : null}

            <LoginForm
              defaultEmail={email}
              handleSubmit={handleSubmitAsync}
            />

            <div className="flex flex-col gap-4 items-center">
              <Button
                asChild
                variant="link"
                className="text-foreground underline hover:text-primary"
              >
                <Link to="/reset-password">
                  {t("loginPage.password.forgot")}?
                </Link>
              </Button>

              <hr className="w-4/5 my-4 mx-auto" />
              <h2 className="text-lg font-bold">{t("loginPage.notMember")}</h2>

              <Button
                asChild
                variant="link"
                className="text-foreground underline hover:text-primary"
              >
                <Link to="/sign-up">{t("loginPage.sign.up.now")}</Link>
              </Button>
            </div>
          </>
        )}

        {verificationId && resolver && (
          <CodeLogin
            verificationId={verificationId}
            resolver={resolver}
            successfulLogin={handleLoginSuccessAsync}
            handleCancel={() => {
              setVerificationId(undefined)
              setResolver(undefined)
            }}
            handleResendCode={handleResendCodeAsync}
            handleError={(error: string) => setError(error)}
          />
        )}
      </BoxContainer>

      <div id="login-recaptcha-container" />
    </>
  )
}
