import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '~/context/AuthContext'
import { useUserProfile } from '~/hooks/useUserProfile'

import ContractsCard from './ContractsCard'
import PersonalDetailsCard from './PersonalDetailsCard'
import SecurityCard from './SecurityCard'
import SubscriptionsCard from './SubscriptionsCard'

export default function Account() {
  const navigate = useNavigate()

  // User profile
  const [, , isPending] = useUserProfile()

  const { verifyIfUserIsEnrolledInMultiFactor } = useAuth()
  const [mfaEnabled, setMfaEnabled] = useState(verifyIfUserIsEnrolledInMultiFactor())

  useEffect(() => {
    const mfa = verifyIfUserIsEnrolledInMultiFactor()
    setMfaEnabled(mfa)
    return () => {}
  }, [])

  const mfaEnabledCallback = (mfa: boolean) => {
    setMfaEnabled(mfa)
  }

  const navigateToMFASetup = () => {
    navigate('/setup-mfa')
  }

  return (
    <div className="overflow-y-auto h-full bg-primary-cream-300">
      <h2
        className={`p-6 text-primary-black text-[2.5rem] sm:text-[64px] font-medium leading-normal tracking-tighter font-platypi`}
      >
        Account
      </h2>

      {isPending ? (
        <div className="flex justify-center items-center h-screen text-lg">
          Fetching your profile...
        </div>
      ) : (
        <div className="flex flex-col max-w-[50rem] mx-auto px-2 py-2 md:px-6 md:py-3 space-y-4">
          <PersonalDetailsCard mfaEnabled={mfaEnabled} />

          <SubscriptionsCard />

          <SecurityCard navigateToMFASetup={navigateToMFASetup} mfaEnabledCallback={mfaEnabledCallback} />

          <ContractsCard />
        </div>
      )}
    </div>
  )
}
