import React from "react"
import { doc, setDoc } from "@firebase/firestore"
import { FirebaseError } from "@firebase/util"
import { useMutation } from "@tanstack/react-query"
import { logEvent } from "firebase/analytics"
import { Circle, CircleCheckBig } from "lucide-react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { BaseControl } from "~/components/base/base-control"
import { InlineCheckbox } from "~/components/base/inline-checkbox"
import BoxContainer from "~/components/BoxContainer"
import CountrySelector from "~/components/CountrySelector"
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SubmitButton,
  toast,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions } from "~/lib/react-query"
import { analytics, db } from "~/services/firebase"
import { supportedCountries } from "~/utils/countries"
import { supportedDictationLanguages } from "~/utils/languages"

const isEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const detectLanguageCode = () => {
  const language = (window as any)?.navigator?.language // e.g., "en-US"
  if (!language) return ""
  return language.split("-")[0]?.toLocaleLowerCase()
}

function useRegisterUser({
  ...options
}: MutationOptions<{
  email: string
  password: string
  country: { code: string }
  tac: boolean
}>) {
  const { signup, sendVerificationEmail } = useAuth()

  const language = React.useMemo(() => {
    const navigatorLanguageCode = detectLanguageCode()

    return supportedDictationLanguages.some(
      (lang) => lang.code === navigatorLanguageCode
    )
      ? navigatorLanguageCode
      : supportedDictationLanguages[0].code
  }, [])

  const handleFirebaseError = (error: FirebaseError) => {
    const errorMessages: Record<string, string> = {
      "auth/email-already-in-use": "Email already in use. Sign in instead.",
      "auth/too-many-requests": "Too many requests. Please try again later.",
    }

    const message = errorMessages[error.code] || error.message
    toast.error(message)
  }

  return useMutation({
    ...options,
    mutationFn: async ({ email, password, country }) => {
      // Sign up user
      const signupData = await signup(email, password)

      // Send verification email
      await sendVerificationEmail(signupData.user)

      // Set user country to user profile
      if (country?.code && language) {
        // Update user document with country code and language
        const dataRef = doc(db, `users/${signupData.user.uid}`)

        await setDoc(
          dataRef,
          {
            userProfile: {
              regCountryCode: country.code,
              language: language,
              dictationLanguage: language,
            },
          },
          {
            merge: true,
          }
        )
      }

      logEvent(analytics, "sign_up")
    },
    onError: (error: any) => {
      if (error instanceof FirebaseError) {
        handleFirebaseError(error)
      } else {
        toast.error(error?.message ?? "An unknown error occurred")
      }
    },
  })
}

export default function SignUp() {
  const navigate = useNavigate()

  const [supportedCountry, setSupportedCountry] = React.useState(true)

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      country: null,
      tac: false,
    },
  })

  const registerUser = useRegisterUser({
    onSuccess: () => {
      navigate("/verify-email")
    },
  })

  return (
    <BoxContainer>
      <div className="flex flex-col items-center gap-1">
        <h2 className="w-full py-0.5 text-2xl font-bold text-center">
          Register and start taking notes
        </h2>
        <p className="text-center">No credit card needed.</p>
      </div>

      <Form
        form={form}
        className="space-y-6"
        onSubmit={form.handleSubmit(async (values) => {
          await registerUser.mutateAsync(values as any)
        })}
      >
        <BaseControl
          name="email"
          label="Email"
          rules={{
            required: "Email is required",
            validate: (v) => isEmail(v) || "Invalid email address",
          }}
          render={({ field }) => (
            <Input
              {...field}
              required
              type="email"
              placeholder={"Enter your email"}
            />
          )}
        />

        <FormField
          name="password"
          rules={{
            required: "Password is required",
            validate: (v) => {
              if (v.length < 8 || v.length > 256) {
                return "Password must be at least 8 characters, and no more than 256 characters long"
              }
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  required
                  type="password"
                  placeholder={"•••••••"}
                />
              </FormControl>
              <FormDescription className="flex items-center gap-1.5">
                {field.value?.length >= 8 ? (
                  <CircleCheckBig
                    data-testid="checked-circle"
                    className="size-4 text-situational-success"
                  />
                ) : (
                  <Circle
                    data-testid="circle"
                    className="size-4"
                  />
                )}
                <span>Minimum 8 characters</span>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <BaseControl
          name="country"
          label="Country of practice"
          rules={{
            required: "Country is required",
          }}
          description={
            <>
              {!supportedCountry && (
                <span className="text-[#d36c65]">
                  Please note that your data will be processed within the EU in
                  accordance to GDPR and&nbsp;
                  <Link
                    className="underline underline-always"
                    to="https://joy.day/service-agreement"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Joy Terms and Conditions
                  </Link>
                  .
                </span>
              )}
            </>
          }
          render={({ field }) => (
            <CountrySelector
              {...field}
              props={{ required: true }}
              handleSelectedCountry={(country) => {
                field.onChange(country)
                setSupportedCountry(
                  country ? supportedCountries.includes(country) : true
                )
              }}
            />
          )}
        />

        <FormField
          name="tac"
          rules={{
            required: "Please agree Joy Terms of Service",
          }}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InlineCheckbox
                  {...field}
                  id="tac"
                  checked={field.value}
                  defaultChecked={false}
                  onCheckedChange={field.onChange}
                  label={
                    <span>
                      I agree with{" "}
                      <Link
                        className="underline"
                        to="https://joy.day/service-agreement"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Joy Terms and Conditions
                      </Link>
                    </span>
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <SubmitButton
          type="submit"
          className="w-full"
          isSubmitting={form.formState.isSubmitting}
        >
          Sign up
        </SubmitButton>
      </Form>

      <p className="text-sm text-center text-[#d36c65]">
        Having trouble? Drop us a line at support@joy.day
      </p>

      <hr className="w-4/5 mx-auto mt-4" />

      <Button
        asChild
        size="sm"
        variant="link"
        className="text-foreground underline hover:text-primary"
      >
        <Link to="/login">Already have an account?</Link>
      </Button>
    </BoxContainer>
  )
}
