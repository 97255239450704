import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Label, Button } from '~/components/ui'
import { useAuth } from '~/context/AuthContext'
import { ActionCodeInfo } from 'firebase/auth'
import BoxContainer from '~/components/BoxContainer'
import PasswordLengthInfo from '~/components/PasswordLength'

type VerificationState = 'success' | 'error' | null

interface PasswordResetFormElements extends HTMLFormControlsCollection {
  password: HTMLInputElement
  passwordConfirmed: HTMLInputElement
}

interface PasswordResetFormElement extends HTMLFormElement {
  readonly elements: PasswordResetFormElements
}

// eslint-disable-next-line @typescript-eslint/ban-types
const PasswordResetHandler = ({ handlePasswordReset }: { handlePasswordReset: Function }) => {
  const [passwordLength, setPasswordLength] = useState<number>(0)
  const [error, setError] = useState<string | undefined>()

  return (
    <BoxContainer>
      <h2 className='text-2xl font-bold'>
        Password reset
      </h2>
      <form
        className='flex flex-col gap-4'
        onSubmit={async (event: React.FormEvent<PasswordResetFormElement>) => {
          event.preventDefault()
          const formElements = event.currentTarget.elements
          const data = {
            password: formElements.password.value,
            passwordConfirmed: formElements.passwordConfirmed.value,
          }
          try {
            // Verify password length
            if (data.password.length < 8 || data.password.length > 256) {
              setError('Password must be at least 8 characters, and no more than 256 characters long')
              return
            }
            // Verify password match
            if (data.password !== data.passwordConfirmed) {
              setError('Passwords do not match')
              return
            }
            setError(undefined)
            await handlePasswordReset(data.password)
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <div className='flex flex-col'>
          <Label className='pb-1.5'>New password</Label>
          <input
            className='rounded-md border-[#cdd7e1] text-normal-black-300'
            placeholder="•••••••"
            type="password"
            required
            name="password"
            onChange={(event) => {
              setPasswordLength(event.target.value.length)
            }}
          />
          <PasswordLengthInfo passwordLength={passwordLength} />
        </div>

        <div className='flex flex-col'>
          <Label className='pb-1.5'>Confirm password</Label>
          <input
            className='rounded-md border-[#cdd7e1] text-normal-black-300'
            placeholder="•••••••"
            required
            type="password"
            name="passwordConfirmed" />
        </div>

        {error ? (
          <p className='text-[#c41c1c]'>
            {error}
          </p>
        ) : null}

        <Button
          id="sign_up"
          type="submit"
          // variant='ocean-blue'
          className='mt-4 py-1.5 px-4 rounded-md font-bold text-[14px]'
        >
          Reset password
        </Button>
      </form>
    </BoxContainer>
  )
}

const PasswordResetSuccess = () => {
  return (
    <div>
      <h2 className='text-2xl font-bold mb-6'>
        Your password has been reset.
      </h2>
      <Link className='underline underline-always' to="/login">
        Continue to login
      </Link>
    </div>
  )
}

const PasswordResetError = () => {
  return (
    <div>
      <h2 className='text-2xl font-bold'>
        Failed resetting password.
      </h2>
      <p className='my-2 mb-6 text-[14px] text-[#555E68]'>
        There was an error resetting your password. Please try again or email us at support@joy.day.
      </p>
      <Link className='underline underline-always' to="/reset-password">
        Restart password reset
      </Link>
    </div>
  )
}

const EmailVerificationSuccess = ({ email }: { email: string }) => {
  const loginLink = `/login?${new URLSearchParams({ email }).toString()}`

  return (
    <div>
      <h2 className='text-2xl font-bold mb-6'>
        Great! Your email is verified.
      </h2>
      <Link className='underline underline-always' to={loginLink}>
        Continue to login
      </Link>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const EmailVerificationError = ({ resendVerificationEmail }: { resendVerificationEmail: Function }) => {
  return (
    <div>
      <h2 className='text-2xl font-bold'>
        Failed verifying email
      </h2>
      <p className='my-2 mb-6 text-[14px]'>
        There was an error verifying your email. Please try again or email us at support@joy.day.
      </p>

      <Button
        className='w-full py-1.5 px-4 font-bold text-[14px]'
        onClick={async () => {
          resendVerificationEmail()
        }}
        // variant='ocean-blue'
      >
        Send new verification email
      </Button>
    </div>
  )
}

const UnEnroll2FAVerificationSuccess = () => (
  <div>
    <h2 className='text-2xl font-bold mb-6'>
      Great! Your multi-factor authentication is disabled.
    </h2>
    {/* User needs to re-login because this action invalidates the auth token */}
    <Link className='underline underline-always' to="/login">
      Back to login
    </Link>
  </div>
)

const UnEnroll2FAVerificationError = () => (
  <div>
    <h2 className='text-2xl font-bold'>
      Failed disabling multi-factor authentication
    </h2>
    <p className='my-2 mb-6 text-[14px]'>
      There was an error disabling your multi-factor authentication. Please try again or email us at support@joy.day.
    </p>
    {/* User needs to re-login because this action invalidates the auth token */}
    <Link className='underline underline-always' to="/login">
      Back to login
    </Link>
  </div>
)

const ActionCodeError = () => {
  return (
    <div>
      <h2 className='text-2xl font-bold mb-2'>
        Invalid action link
      </h2>
      <Link className='underline underline-always' to="/login">
        Back to login
      </Link>
      <p className='self-center text-[#d36c65] mt-6'>
        Having trouble? Drop us a line at support@joy.day
      </p>
    </div>
  )
}

const ActionHandler = () => {
  const [verificationState, setVerificationState] = useState<VerificationState>(null)
  const [actionCode, setActionCode] = useState<string | null>(null)
  const [actionInfo, setActionInfo] = useState<ActionCodeInfo | null>(null)

  const { applyAuthActionCode, checkAuthActionCode, confirmPasswordResetWithActionCode, resendVerificationEmail } =
    useAuth()
  const location = useLocation()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    setActionCode(urlParams.get('oobCode'))
  }, [location.search])

  const handlePasswordReset = async (newPassword: string) => {
    try {
      await confirmPasswordResetWithActionCode(actionCode!, newPassword)
      setVerificationState('success')
    } catch (err) {
      setVerificationState('error')
      console.error(err)
    }
  }

  useEffect(() => {
    if (!actionCode) {
      return
    }

    async function handleAction() {
      try {
        const actionInfo: ActionCodeInfo = await checkAuthActionCode(actionCode)
        setActionInfo(actionInfo)

        switch (actionInfo.operation) {
          case 'PASSWORD_RESET':
            break
          case 'VERIFY_EMAIL':
          case 'REVERT_SECOND_FACTOR_ADDITION':
            try {
              await applyAuthActionCode(actionCode)
              setVerificationState('success')
            } catch (err) {
              setVerificationState('error')
              console.error(err)
            }
            break
          default:
            break
        }
      } catch (error) {
        setVerificationState('error')
      }
    }

    handleAction()
  }, [actionCode, applyAuthActionCode, checkAuthActionCode])

  const renderHandler = () => {
    if (!actionInfo) {
      return verificationState === 'error' ? <ActionCodeError /> : <div>Loading...</div>
    }

    switch (actionInfo.operation) {
      case 'PASSWORD_RESET':
        if (verificationState === 'success') {
          return <PasswordResetSuccess />
        } else if (verificationState === 'error') {
          return <PasswordResetError />
        }
        return <PasswordResetHandler handlePasswordReset={handlePasswordReset} />
      case 'VERIFY_EMAIL':
        if (verificationState === 'success') {
          const email = actionInfo.data.email ? actionInfo.data.email : ''
          return <EmailVerificationSuccess email={email} />
        } else if (verificationState === 'error') {
          return <EmailVerificationError resendVerificationEmail={resendVerificationEmail} />
        }
        return <div>Verifying...</div>
      case 'REVERT_SECOND_FACTOR_ADDITION':
        if (verificationState === 'success') {
          return <UnEnroll2FAVerificationSuccess />
        } else if (verificationState === 'error') {
          return <UnEnroll2FAVerificationError />
        }
        return <div>Verifying...</div>
      default:
        return <ActionCodeError />
    }
  }

  return (
    <>
      <BoxContainer>
        {renderHandler()}
      </BoxContainer>
    </>
  )
}

export default ActionHandler
