import {
  collection,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore"
import { Improvement, Note, NoteStatus } from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import { parseTranscript } from "./transcriptParsers"

export const getNoteData = async (
  noteRef: DocumentReference
): Promise<Note | null> => {
  const noteSnapshot = await getDoc(noteRef)
  if (noteSnapshot.exists()) {
    const note = noteSnapshot.data() as Note
    note.id = noteSnapshot.id
    return note
  }
  console.error("No such note!")
  return null
}

export const fetchImprovements = async (note: Note, userId: string) => {
  const improvementsRef = collection(
    db,
    `users/${userId}/notes/${note.id}/improvements`
  )
  const q = query(improvementsRef)
  const snapshot = await getDocs(q)
  return snapshot.docs.length > 0
    ? (snapshot.docs[0].data() as Improvement)
    : null
}

export const getTranscribedNote = async (
  note: Note,
  noteRef: DocumentReference,
  userID: string
) => {
  let improvement
  switch (note.status) {
    case NoteStatus.Edited:
      return note.editedTranscription.replace(/\\n/g, "\n")
    case NoteStatus.Improved:
      return note.improvedTranscription.replace(/\\n/g, "\n")
    case NoteStatus.ImprovementRequested:
      improvement = await fetchImprovements(note, userID)
      if (improvement) {
        await updateDoc(noteRef, {
          status: NoteStatus.Improved,
          improvedTranscription: improvement.improvedTranscription,
        })
        return improvement.improvedTranscription.replace(/\\n/g, "\n")
      }
      return parseTranscript(note.transcription)
    case NoteStatus.Transcribed:
      return parseTranscript(note.transcription)
    case NoteStatus.Error:
      return `Error transcribing note \n\n ${note.transcription}`
    default:
      return "Not transcribed yet"
  }
}

export const clientFields = [
  { name: "address", type: "text", value: "" },
  { name: "ZIP", type: "integer", value: "" },
  { name: "city", type: "text", value: "" },
  { name: "comment", type: "text", value: "" },
]

// create client note map from previous notes
export const createClientNoteMap = (
  prevNotes: Note[]
): {
  [key: string]: Note
} => {
  const clientNoteMap: { [key: string]: Note } = {}
  prevNotes.forEach((note) => {
    if (isValidNoteForDisplay(note)) {
      updateClientNoteMap(clientNoteMap, note, note.id)
    }
  })
  return clientNoteMap
}

// check if noteA is newer than noteB
const isNoteNewer = (noteA: Note, noteB: Note): boolean =>
  noteA?.createdAt &&
  noteB?.createdAt &&
  noteA.createdAt.seconds > noteB.createdAt.seconds

// update the client note map
export const updateClientNoteMap = (
  clientNoteMap: { [key: string]: Note },
  note: Note,
  noteId: string
) => {
  const clientId = note.clientId

  if (!clientId) {
    clientNoteMap[noteId] = note
    return
  }

  const existingNote = clientNoteMap[clientId]

  if (!existingNote || isNoteNewer(note, existingNote)) {
    clientNoteMap[clientId] = note
  }
}

export const getSortedNotes = (clientNoteMap: { [key: string]: Note }) => {
  return Object.values(clientNoteMap).sort((previousNote, nextNote) => {
    if (!previousNote.createdAt || !nextNote.createdAt) {
      return 0
    }

    return nextNote.createdAt.seconds - previousNote.createdAt.seconds
  })
}

// @todo: Optimize this function
export const isValidNoteForDisplay = (note: Note) => {
  if (note.deletedAt || note.viewedWithUndo) {
    return false
  }
  if (note.clientId && (note.deletedAt || note.viewedWithUndo)) {
    return false
  }
  return true
}
