import { useEffect, useState } from 'react'

import { useAuth } from '~/context/AuthContext'
import { doc, getDoc } from 'firebase/firestore'
import PropTypes from 'prop-types'
import { db } from '~/services/firebase'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/components/ui"

interface ClientData {
  name?: string
  phone?: string
  email?: string
  [key: string]: string | undefined
}

interface ClientDetailsModalProps {
  isOpen: boolean
  clientId: string
  editButtonHandler: () => void
  setOpenClientDetailsModal: (x: boolean) => void
}

const ClientDetailsModal: React.FC<ClientDetailsModalProps> = ({
  isOpen,
  setOpenClientDetailsModal,
  clientId,
  editButtonHandler,
}) => {
  const { currentUser } = useAuth()
  const [clientData, setClientData] = useState<ClientData>({})

  useEffect(() => {
    const fetchClient = async () => {
      if (!currentUser || !clientId) return

      try {
        const clientRef = doc(db, `users/${currentUser.uid}/clients/${clientId}`)
        const clientSnap = await getDoc(clientRef)

        if (clientSnap.exists()) {
          setClientData(clientSnap.data() as ClientData)
        } else {
          console.log('No such document!')
        }
      } catch (error) {
        console.error('Error getting client document:', error)
      }
    }

    if (isOpen) {
      fetchClient()
    }
  }, [isOpen, currentUser, clientId])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={setOpenClientDetailsModal}
    >
      <DialogContent className="bg-primary-cream-300" aria-describedby="">
        <DialogHeader>
          <DialogTitle className="sr-only">Client Info</DialogTitle>

          <button
            onClick={(event) => {
              event.preventDefault()
              setOpenClientDetailsModal(false)
              editButtonHandler()
            }}
            className="text-lg font-medium leading-5 py-[0.875rem] px-8 bg-medium-grey-400 rounded-[0.625rem] font-archivo w-28"
          >
            Edit
          </button>
        </DialogHeader>

        <div className="py-5">
          <p className="font-bold text-primary-black text-[1.75rem] font-platypi capitalize">{clientData.name}</p>
        </div>
        {clientData.phone && (
          <div className="pb-4">
            <a
              href={`tel:${clientData.phone}`}
              className="font-medium flex justify-center px-5 py-[0.875rem] rounded-[0.625rem] text-lg text-white bg-primary-black font-archivo"
            >
              {clientData.phone}
            </a>
          </div>
        )}
        {clientData.email && (
          <div className="pb-4">
            <a
              href={`mailto:${clientData.email}`}
              className="font-medium flex justify-center px-5 py-[0.875rem] rounded-[0.625rem] text-lg text-white bg-primary-black font-archivo"
            >
              {clientData.email}
            </a>
          </div>
        )}
        {Object.keys(clientData).map(
          (key) =>
            key !== 'name' &&
            key !== 'phone' &&
            key !== 'email' && (
              <div className="pb-2" key={key}>
                <p className="font-medium flex justify-center px-5 py-[0.875rem] rounded-[0.625rem] text-lg text-primary-black font-archivo">
                  {clientData[key]}
                </p>
              </div>
            ),
        )}
      </DialogContent>
    </Dialog>
  )
}

ClientDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpenClientDetailsModal: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  editButtonHandler: PropTypes.func.isRequired,
}

export default ClientDetailsModal
