import React, { ReactNode } from 'react'

interface HeaderProps {
  classes: string
  children: ReactNode
}
export default function Header({ classes, children }: HeaderProps) {
  return (
    <header className={`py-2 px-4 gap-4 flex flex-row justify-between items-center col-span-full top-0 z-50 ${classes}`} >
      {children}
    </header>
  )
}
