import React, { useEffect, useRef, useState } from 'react'

import { useAuth } from '~/context/AuthContext'
import {
  FieldValue,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/components/ui"

import PropTypes from 'prop-types'
import { db } from '~/services/firebase'

import { formatFieldName, removeDuplicateFields } from '../utils/clientHelpers'
import { validateField } from './../schema/ClientDetailsFormSchema'
import AddFieldModal from './AddFieldModal'

interface Field {
  name: string
  type: string
  value: string
}

interface EditClientModalProps {
  isOpen: boolean
  clientId: string
  noteId: string
  setOpenModal: (x: boolean) => void
  onSuccess: (noteId: string) => Promise<string | null>
  fetchNotesByClientId: (userId: string, clientId: string) => unknown
  addedFields: Field[]
  setAvailableFields: React.Dispatch<React.SetStateAction<Field[]>>
  availableFields: Field[]
  onCloseAllModals: () => void
}

const EditClientModal: React.FC<EditClientModalProps> = ({
  isOpen,
  setOpenModal,
  clientId,
  noteId,
  onSuccess,
  fetchNotesByClientId,
  addedFields,
  setAvailableFields,
  availableFields,
  onCloseAllModals,
}) => {
  const defaultFields = [
    { name: 'name', type: 'text', value: '' },
    { name: 'ID', type: 'text', value: '' },
    { name: 'phone', type: 'tel', value: '' },
    { name: 'email', type: 'email', value: '' },
  ]

  const [fields, setFields] = useState<Field[]>(defaultFields)
  const [focusedField, setFocusedField] = useState<string | null>(null)
  const [showAddFieldModal, setShowAddFieldModal] = useState(false)
  const [filteredFields, setFilteredFields] = useState<Field[]>([])
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const { currentUser } = useAuth()
  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({})

  useEffect(() => {
    if (isOpen) {
      const fetchClient = async () => {
        if (!currentUser || !clientId) return

        try {
          const clientRef = doc(db, `users/${currentUser.uid}/clients/${clientId}`)
          const clientSnap = await getDoc(clientRef)

          if (clientSnap.exists()) {
            const clientData = clientSnap.data()
            const initialFields = defaultFields.map((field) => ({
              ...field,
              value: clientData[field.name] || '',
            }))
            const additionalFields = Object.keys(clientData)
              .filter((key) => !defaultFields.some((f) => f.name === key))
              .map((key) => ({
                name: key,
                type: typeof clientData[key] === 'string' ? 'text' : 'number',
                value: clientData[key] || '',
              }))
            const allFields = removeDuplicateFields([...initialFields, ...additionalFields])
            const filteredFields = allFields.filter(
              (field) =>
                field.value.trim() !== '' || defaultFields.some((defaultField) => defaultField.name === field.name),
            )
            setFields(filteredFields)
          } else {
            console.log('No such document!')
          }
        } catch (error) {
          console.error('Error getting client document:', error)
        }
      }

      fetchClient()
    }
  }, [isOpen, currentUser, clientId])

  useEffect(() => {
    const filtered = availableFields.filter(
      (field) => !fields.some((f) => f.name.toLowerCase() === field.name.toLowerCase()),
    )
    setFilteredFields(filtered)
  }, [availableFields, fields])

  useEffect(() => {
    if (addedFields.length > 0) {
      const newFields = addedFields.map((field) => ({ ...field, value: '' }))
      setFields((prevFields) => {
        const updatedFields = removeDuplicateFields([...prevFields, ...newFields])
        const lastAddedField = newFields[newFields.length - 1].name
        setFocusedField(lastAddedField)
        return updatedFields
      })
      setAvailableFields((prevFields) =>
        prevFields.filter((field) => !newFields.some((newField) => newField.name === field.name)),
      )
    }
  }, [addedFields, setAvailableFields])

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const updatedFields = [...fields]
    updatedFields[index] = { ...updatedFields[index], value }

    validateField(updatedFields[index], setValidationErrors)
    setFields(updatedFields)
  }

  const handleAddField = (field: { name: string; type: string }) => {
    setFields((prevFields) =>
      removeDuplicateFields([...prevFields, { name: field.name.toLowerCase(), type: field.type, value: '' }]),
    )
    setFocusedField(field.name.toLowerCase())
    setShowAddFieldModal(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, field: Field) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setFocusedField(field.name)
    }
  }

  useEffect(() => {
    if (focusedField) {
      setTimeout(() => {
        if (inputRefs.current[focusedField]) {
          inputRefs.current[focusedField]?.focus()
        }
      }, 0)
    }
  }, [focusedField, fields])

  const updateClient = async () => {
    const clientObject: { [key: string]: string | FieldValue } = {}
    fields.forEach((item) => {
      if (item.value && item.value.trim() !== '') {
        clientObject[item.name] = item.value
      } else if (item.name !== 'name') {
        clientObject[item.name] = deleteField()
      }
    })

    const clientRef = doc(db, `users/${currentUser.uid}/clients/${clientId}`)
    await updateDoc(clientRef, clientObject)

    const notesQuery = query(collection(db, `users/${currentUser.uid}/notes/`), where('clientId', '==', clientId))
    const querySnapshot = await getDocs(notesQuery)
    const batch = writeBatch(db)

    querySnapshot.forEach((document) => {
      const noteRef = doc(db, `users/${currentUser.uid}/notes/${document.id}`)
      batch.update(noteRef, { title: clientObject.name || '' })
    })

    await batch.commit()

    const clientDoc = await onSuccess(noteId || '')
    if (clientDoc) {
      fetchNotesByClientId(currentUser?.uid || '', clientDoc)
    }
  }

  const handleClose = () => {
    setOpenModal(false)
    setFields((fields) =>
      fields.filter(
        (field) => ['name', 'ID', 'phone', 'email'].includes(field.name) || (field.value && field.value.trim() !== ''),
      ),
    )
    setValidationErrors([])
    setAvailableFields((prevFields) => [...prevFields, ...addedFields])
    onCloseAllModals()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            handleClose()
          }
        }}
      >
        <DialogContent className="no-scrollbar bg-primary-cream-300" aria-describedby="">
          <DialogHeader>
            <DialogTitle className="sr-only">Edit Client</DialogTitle>

            <button
              onClick={(event) => {
                event.preventDefault()
                if (validationErrors.length === 0) {
                  handleClose()
                  updateClient()
                }
              }}
              className={`bg-black px-5 py-[0.875rem] text-lg leading-[1.375rem] text-white rounded-[0.625rem] w-28 font-archivo ${
                validationErrors.length > 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={validationErrors.length > 0}
            >
              Save
            </button>
          </DialogHeader>

          {fields.map((field, index) => (
            <div
              key={index}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => handleKeyDown(e, field)}
              className={`mb-2 block w-fill-available mx-5 px-4 py-3 rounded-lg bg-white font-archivo cursor-text ${
                focusedField === field.name ? 'border-[0.188rem] border-solid border-bright_coral-400' : 'border-none'
              }`}
              onClick={() => setFocusedField(field.name)}
            >
              {focusedField === field.name || field.value ? (
                <>
                  <label
                    htmlFor={field.name}
                    className="flex justify-start text-medium-grey-300 font-normal text-base cursor-text"
                  >
                    {formatFieldName(field.name)}
                  </label>
                  <input
                    id={field.name}
                    ref={(el) => (inputRefs.current[field.name] = el)}
                    type={field.type}
                    value={field.value}
                    onChange={(e) => handleInputChange(index, e)}
                    onBlur={() => {
                      if (field.value && field.value.trim() === '') {
                        setFields((prevFields) => prevFields.map((f, i) => (i === index ? { ...f, value: '' } : f)))
                      }
                    }}
                    className="text-lg font-medium leading-9 bg-white p-0 text-normal-black-300 custom-input w-full"
                    required
                    pattern={field.type === 'email' ? '^\\S+@\\S+\\.\\S+$' : undefined}
                    title={field.type === 'email' ? 'Please enter a valid email address' : undefined}
                    minLength={field.type === 'tel' ? 10 : undefined}
                    maxLength={field.type === 'tel' ? 15 : undefined}
                  />
                  {validationErrors.some((err) => err.startsWith(field.name)) && (
                    <p className="text-red-500 text-sm mt-1">Invalid {field.name}</p>
                  )}
                </>
              ) : (
                <div className="text-lg font-medium leading-9 w-full h-16 flex items-center bg-white p-0 text-normal-black-300 custom-input cursor-text">
                  {formatFieldName(field.name)}
                </div>
              )}
            </div>
          ))}

          {filteredFields.length > 0 && (
            <button
              onClick={() => setShowAddFieldModal(true)}
              className="mb-2 w-fill-available mx-5 block text-primary-black font-medium text-lg leading-5 bg-medium-grey-400 center border py-[0.875rem] mt-4 rounded-lg border-none cursor-pointer font-archivo"
            >
              Add field
            </button>
          )}
        </DialogContent>
      </Dialog>

      <AddFieldModal
        show={showAddFieldModal}
        onClose={() => setShowAddFieldModal(false)}
        onAddField={handleAddField}
        existingFields={fields}
        availableFields={filteredFields}
      />
    </>
  )
}

EditClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  noteId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  fetchNotesByClientId: PropTypes.func.isRequired,
  addedFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  setAvailableFields: PropTypes.func.isRequired,
  availableFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onCloseAllModals: PropTypes.func.isRequired,
}

export default EditClientModal
