import React, { useEffect, useState } from "react"
import { doc, FirestoreError, onSnapshot } from "firebase/firestore"
import { AlertCircle } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "~/components/ui"
import useCrisp from "~/hooks/useCrisp"
import { db } from "~/services/firebase"
import { UserType } from "~/types/userTypes"
import { useAuth } from "./AuthContext"

export const UserContext = React.createContext<UserType | null>(null)

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { currentUser } = useAuth()
  const { resetSession, setSignedEmail, setUserToken } = useCrisp()

  const [user, setUser] = useState<UserType | null>(null)
  const [userError, setUserError] = useState<FirestoreError | undefined>()

  // Update crisp session on user change
  useEffect(() => {
    async function updateCrispSession() {
      if (user && currentUser) {
        if (user?.userProfile?.crispUid) {
          setUserToken(user.userProfile.crispUid)
        }
        if (currentUser?.emailVerified) {
          await setSignedEmail(
            user.userProfile.email ? user.userProfile.email : ""
          )
        }
      } else {
        resetSession() // Reset crisp session
      }
    }

    updateCrispSession()

    // Only run on user change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentUser])

  // Handle the service worker registration
  // Since it uses firebase, we only register it once the firebase
  // user is available. Note that the unregister is taken care of
  // in the logout method in main
  useEffect(() => {
    async function registerServiceWorker() {
      if (!user) {
        // User has logged out or we don't have a valid current user

        return
      }

      const enableServiceWorker =
        import.meta.env.VITE_JOY_SERVICE_WORKER_DISABLE !== "true"

      if (enableServiceWorker && "serviceWorker" in navigator) {
        const isProdLike = ["production", "staging"].includes(
          import.meta.env.MODE
        )
        const envScript = isProdLike ? "/sw.js" : "/dev-sw.js?dev-sw"
        const envLoad = isProdLike ? "classic" : "module"

        try {
          // It's safe to call this multiple times for the same context
          await navigator.serviceWorker.register(envScript, {
            type: envLoad,
            scope: "/",
          })
        } catch (err) {
          console.error("Error during service worker registration:", err)
        }
      }
    }

    registerServiceWorker()
  }, [user])

  // Listen to user changes
  // Fetch user data from firestore
  useEffect(() => {
    if (!currentUser || !currentUser.uid) {
      setUser(null)
      return () => {}
    }

    // Listen to user document changes
    const userDocRef = doc(db, "users", currentUser?.uid)
    const unsubscribe = onSnapshot(
      userDocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setUser(docSnap.data() as UserType)
        } else {
          setUser(null)
        }
      },
      (error) => {
        setUserError(error)
      }
    )
    return () => unsubscribe()
  }, [currentUser])

  return userError ? (
    <div className="container max-w-screen-md mx-auto h-full flex flex-col items-center pt-8">
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>
          <strong>Internal error</strong>
          <span>{userError?.message ?? ""}</span>
        </AlertTitle>
        <AlertDescription>
          Try refreshing the page or reach out to support@joy.day.
        </AlertDescription>
      </Alert>
    </div>
  ) : (
    <UserContext.Provider value={user}>{children}</UserContext.Provider>
  )
}
